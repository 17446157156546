import "@babel/polyfill";
import Vue from "vue";
import "mutationobserver-shim";
import "./config/firebase";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Raphael from "raphael/raphael";
import "./plugins";
import "./registerServiceWorker";
import AlgoliaComponents from "vue-instantsearch";
import i18n from "./i18n";
import "./directives";
import axios from "./axios-auth";
import VueAxios from "vue-axios";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import ChatComponent from "./components/Global-Component/client-communication/index.vue";
Vue.component("ChatComponent", ChatComponent);
// start vue toast notification

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast, {
  position: "bottom-right",
  containerClassName: "custom-toast-container",
});

// end vue toast notification
import moment from "moment";
import VuejsDialog from "vuejs-dialog";
import Paginate from "vuejs-paginate";
Vue.use(VueAxios, axios);
Vue.component("paginate", Paginate);
import pageRecord from "./components/common/page-record";
Vue.component("page-record", pageRecord);
import "vuejs-dialog/dist/vuejs-dialog.min.css";
Vue.use(VuejsDialog, {
  html: true,
  okText: "Ok",
  cancelText: "Cancel",
  animation: "bounce",
});
global.Raphael = Raphael;
require("./plugins/vee-validate");
Vue.use(AlgoliaComponents);
Vue.filter("formatDate", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM/DD/YY");
});

Vue.filter("formatDate24", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM/DD/YY HH:mm:ss");
});

Vue.filter("monthday", function (value) {
  if (!value) return "";
  return moment(String(value)).format("DD MMM");
});
Vue.filter("formatDate1", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM-DD-YY");
});
Vue.filter("formatTime", function (value) {
  if (!value) return "";
  return moment(String(value)).format("hh:mm");
});

Vue.filter("formatTime24", function (value) {
  if (!value) return "";
  return moment(String(value)).format("HH:mm");
});

Vue.filter("formatDateTime", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM/DD/YY hh:mm:ss");
});

Vue.filter("DOB", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM/DD/YYYY");
});

Vue.filter("formatDateTime24", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM/DD/YY HH:mm:ss");
});

import "remixicon/fonts/remixicon.css";
Vue.filter("formatDateTime1", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMM-DD-YY hh:mm:ss");
});
Vue.config.productionTip = false;

window.Pusher = Pusher;
const echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_WS_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WSS_PORT,
  forceTLS: false,
  enabledTransports: ["ws", "wss"],
  authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
    },
  },
});

Vue.prototype.$echo = echo;

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
