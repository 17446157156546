const layout = resolve => {
	require.ensure(
		["@/layouts/Layout2.vue"],
		() => {
			resolve(require("@/layouts/Layout2.vue"));
		},
		"layout"
	);
};
const emailMarketingList = resolve => {
	require.ensure(
		["@/components/Marketing/dynamic-template/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/dynamic-template/list/index.vue"));
		},
		"dynamic-template"
	);
};
const emailMarketingManage = resolve => {
	require.ensure(
		["@/components/Marketing/dynamic-template/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/dynamic-template/manage/index.vue"));
		},
		"dynamic-template-manage"
	);
};

const staticTemplateList = resolve => {
	require.ensure(
		["@/components/Marketing/static-templates/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/static-templates/list/index.vue"));
		},
		"static-template"
	);
};
const staticTemplateManage = resolve => {
	require.ensure(
		["@/components/Marketing/static-templates/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/static-templates/manage/index.vue"));
		},
		"static-template-manage"
	);
};
const emailCampaignList = resolve => {
	require.ensure(
		["@/components/Marketing/email-campaign/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/email-campaign/list/index.vue"));
		},
		"email-campaign"
	);
};
const emailCampaignManage = resolve => {
	require.ensure(
		["@/components/Marketing/email-campaign/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/email-campaign/manage/index.vue"));
		},
		"email-campaign-manage"
	);
};
const emailSenderList = resolve => {
	require.ensure(
		["@/components/Marketing/email-sender/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/email-sender/list/index.vue"));
		},
		"email-sender"
	);
};
const emailSenderManage = resolve => {
	require.ensure(
		["@/components/Marketing/email-sender/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/email-sender/manage/index.vue"));
		},
		"email-sender-manage"
	);
};
const audienceList = resolve => {
	require.ensure(
		["@/components/Marketing/audience/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/audience/list/index.vue"));
		},
		"audience"
	);
};
const audienceManage = resolve => {
	require.ensure(
		["@/components/Marketing/audience/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/audience/manage/index.vue"));
		},
		"audience-manage"
	);
};

const smsMarketingList = resolve => {
	require.ensure(
		["@/components/Marketing/sms-template/list/index.vue"],
		() => {
			resolve(require("@/components/Marketing/sms-template/list/index.vue"));
		},
		"sms-template"
	);
};
const smstemplateManage = resolve => {
	require.ensure(
		["@/components/Marketing/sms-template/manage/index.vue"],
		() => {
			resolve(require("@/components/Marketing/sms-template/manage/index.vue"));
		},
		"sms-template-manage"
	);
};
export default [
	{
		path: "/dynamic-template",
		name: "dynamic-template",
		component: emailMarketingList
	},
	{
		path: "/dynamic-template/add-Dynamic-Template",
		name: "add-Dynamic-Template",
		component: emailMarketingManage
	},
	{
		path: "/dynamic-template/update-Dynamic-Template/:id",
		name: "update-Dynamic-Template",
		component: emailMarketingManage
	},
	{
		path: "/static-template",
		name: "static-template",
		component: staticTemplateList
	},
	{
		path: "/static-template/add-Static-Template",
		name: "add-Static-Template",
		component: staticTemplateManage
	},
	{
		path: "/static-template/update-Static-Template/:id",
		name: "update-Static-Template",
		component: staticTemplateManage
	},
	{
		path: "/email-campaign",
		name: "email-campaign",
		component: emailCampaignList
	},
	{
		path: "/email-campaign/add-Email-campaign",
		name: "add-Email-campaign",
		component: emailCampaignManage
	},
	{
		path: "/email-campaign/update-Email-ampaign/:id",
		name: "update-Email-ampaign",
		component: emailCampaignManage
	},
	{
		path: "/email-sender",
		name: "email-sender",
		component: emailSenderList
	},
	{
		path: "/email-sender/add-email-sender",
		name: "add-email-sender",
		component: emailSenderManage
	},
	{
		path: "/email-sender/update-email-sender/:id",
		name: "email-sender-manage",
		component: emailSenderManage
	},
	{
		path: "/audience",
		name: "audience",
		component: audienceList
	},
	{
		path: "/audience/add-audience",
		name: "add-audience",
		component: audienceManage
	},
	{
		path: "/audience/update-audience/:id",
		name: "update-audience",
		component: audienceManage
	},
	{
		path: "/sms-static-template",
		name: "sms-static-template",
		component: smsMarketingList
	},
	{
		path: "/sms-static-template/add-sms-static-template",
		name: "add-sms-static-template",
		component: smstemplateManage
	},
	{
		path: "/sms-static-template/update-sms-static-template/:id",
		name: "update-sms-static-template",
		component: smstemplateManage
	},
]
